<template>
	<v-card flat color="transparent"
		class="mx-auto" max-width="1200"
	>
		<site-summary/>
		<div class="pa-4">
			<divider color="#eaeaea" height="3" border-radius="50"/>
		</div>
		<attendance-table title="Today's Attendance"
			:url="$util.format($urls.attendance.dateFilter, today)"
		/>
		<leave-table title="Today's Leaves"
			:url="$util.format($urls.leave.dateFilter, today)"
		/>
	</v-card>
</template>

<script>
export default {
	name: "Home",
	components: {
		LeaveTable: () => import("@/views/Leaves"),
		AttendanceTable: () => import("@/views/Attendance"),
		SiteSummary: () => import("@/components/home/SiteSummary")
	},
	computed: {
		today() {
			return this.$moment(new Date()).format("YYYY-MM-DD")
		}
	}
};
</script>
